@import "../../variables.scss";

.layout-root {
    display: flex;

    .item-icon {
        min-width: 2.5rem;
    }

    .drawer {
        flex-shrink: 0;
        position: relative;

        .MuiDrawer-paper {
            background-color: $secondaryColor;
            width: 18.75rem;
            max-width: 80%;
            padding: 0 1.875rem;
            color: $textColor;
            display: flex;
            justify-content: space-between;
            height: 100vh;
        }

        .back-icon {
            background-color: transparent !important;
        }
    }

    .menus {
        margin-top: 0.9375rem;
        flex: 1;
        overflow-y: auto;

        .side-header {
            display: flex;
            align-items: center;
            gap: 20px;
            font-weight: 600;
        }
    }

    .content {
        padding: 1.25rem;
        flex-grow: 1;
        transition: margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        overflow: auto;

        .main-container {
            padding: 1.25rem 0;
            min-height: calc(100vh - 60px - 2.5rem);
            display: flex;
            flex-direction: column;
        }
    }

    .content-shifted {
        margin-left: 18.75rem;
        transition: margin-left 0.2s;
    }
}
