@import "./variables.scss";

* {
    margin: 0;
    padding: 0;
    font-size: 16px;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    color: white !important;

}

.page-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Override Material-UI Typography */
.MuiTypography-root {
    font-family: Poppins, sans-serif !important;
}

/* Override Material-UI Button */
.MuiButton-root {
    font-family: Poppins, sans-serif !important;
}

.MuiIconButton-root {
    border: 0.1875rem solid $secondaryColor !important;
    color: $lightColor !important;
    background-color: $mainBackgroundColor !important;

    &.MuiIconButton-sizeLarge {
        height: 3.75rem;
        width: 3.75rem;
    }
}

.MuiButton-textSecondary {
    color: white !important;
}

.MuiAvatar-colorDefault {
    background-color: transparent !important;
    color: $lightColor !important;
    font-size: 1rem !important;
}

.MuiList-root {
    background-color: $secondaryColor !important;
    color: $textColor !important;
}

.MuiButton-containedPrimary {
    text-decoration: none !important;
    color: $lightColor !important;
}

.MuiDataGrid-virtualScroller {
    min-height: 200px;
    flex-grow: 1;

    .MuiDataGrid-overlay {
        background-color: $mainBackgroundColor;
    }
}
