@import "../../variables.scss";

.bikes {
    .firmware {
        > span > svg {
            margin-left: 0.5rem;

            > * {
                stroke: $primaryColor;
            }
        }
    }
}