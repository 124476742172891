@import "../../../variables.scss";

.side-bar {
    flex-shrink: 0;
    position: relative;

    .MuiDrawer-paper {
        background-color: $secondaryColor;
        width: 18.75rem;
        max-width: 80%;
        padding: 0 1.875rem;
        color: $textColor;
        display: flex;
        justify-content: space-between;
        height: 100vh;
    }

    .logo-container {
        position: relative; /* Allow this item to be positioned relative to its container */
        bottom: 0;
        text-align: center;

        img {
            width: 15rem;
            height: auto;
            margin-bottom: 0.9375rem;
        }
    }
}
