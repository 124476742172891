@import "../../../variables.scss";

.menu {
    margin-top: 0.9375rem;
    flex: 1;
    overflow-y: auto;

    .side-header {
        display: flex;
        align-items: center;
        gap: 20px;
        font-weight: 600;
    }

    .list-items {
        padding: 0.5rem 0;
    }

    .sub-list-items {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .list-text {
        margin-left: 0.625rem;
    }

    .link {
        color: $textColor;
        text-decoration: none;
    }

    .active {
        color: $primaryColor !important;
    }
}
