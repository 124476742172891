@import "../../variables.scss";

.login-form-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        position: fixed;
        top: 7%;
        margin: 0% 7%;
        text-align: center;

        img {
            width: 70%;
            height: auto;
        }
    }

    .login-title {
        color: $textColor;
    }

    .back-text {
        cursor: pointer;
        width: fit-content;
        margin-bottom: 0.625rem !important;
        color: $textColor;
    }

    .login-form {
        width: 31.25rem;
        padding: 1.875rem;
        margin: 1.25rem;
        background-color: $secondaryColor !important;

        @media (max-width: 400px) {
            width: 100%;
            padding: 1.25rem;
        }

        h5 {
            margin-bottom: 1.25rem;
        }

        &__reset_password {
            font-size: 1rem;
            text-align: right;
            margin: 0.625rem 0;
            cursor: pointer;
            color: $primaryColor;
        }

        .MuiFormControl-root {
            margin-bottom: 0.9375rem;
        }
    }
}
