.battery-dashboard {
    height: 100px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    .error {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}