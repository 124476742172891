@import "../../../variables.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        text-transform: uppercase;

        > div {
            font-size: 1.25rem !important;
        }
    }
}
