@import "../../variables.scss";

.bike-tile {
    border-radius: 20px;
    background-color: $lightDark;
    padding: 20px;

    &.super-tiles {
        cursor: pointer;
    }

    .title {
        color: $lightColor;
        font-size: 16px;
    }

    .tiles-info-container {
        color: $textColor;
        padding: 10px 0;
    }

    .tiles-info {
        gap: 10px;
        align-items: center;
        font-size: 16px;
        margin-bottom: 8px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .tiles-icon {
        color: $primaryColor;
        position: relative;
        top: 5px;
        margin-right: 5px;
    }

    .icon-heart {
        position: relative;
        top: 2px;
        margin-left: 5px;
    }

    .tiles-img {
        text-align: center;

        img {
            width: 100px;
            height: 150px;
        }
    }

    .active {
        color: $primaryColor;
    }

    .deActive {
        color: $dangerColor;
    }

    .text {
        margin-left: 0.2rem;
    }
}
