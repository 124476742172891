@import "../../variables.scss";

.value-card {
    > div {
        padding: 1rem;
    }

    .title {
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        color: $textColor;
        
        > span {
            margin-left: 0.5rem;
            font-size: 0.85rem;
        }
    }

    .value-unit {
        margin-top: 1rem;
        .value {
            font-size: 2rem;
        }

        .unit {
            font-size: 1rem;
            margin-left: 0.25rem;
        }
    }
}